<template>
  <div class="markdown-editor">
    <textarea v-model="input" @input="updatePreview" class="markdown-input">
    </textarea>
    <div v-html="markdown_html" ref="display" class="markdown-preview markdown-body"></div>
  </div>
  <div class="markdown-save">
    <a-button @click="update_article()">保存</a-button>
  </div>
</template>

<script>
import { marked } from 'marked'; // 引入marked库
import { ref, onMounted, watch, nextTick } from 'vue';

export default {
  name: 'ArticleEditor',
  props: {
    articleInput: {
      type: String,
      default: '',
    }
  }, 
  computed: {
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const input = ref(props.articleInput);
    const markdown_html = ref('');
    const display = ref(null);
    const updatePreview = () => {
      markdown_html.value = marked(input.value, { sanitize: true });
    };
    let h1Regex = /^#\s(.+)/m;
    const update_article = () => {
      if (input.value) {
        emit('submit', {
          input: input.value,
          title: input.value.match(h1Regex)[1],
          html: markdown_html.value
        })
      }
    }

    onMounted(() => {
      updatePreview();
    })

    watch(markdown_html, () => {
      nextTick().then(() => {
        if (display.value) {
          display.value.scrollTop = display.value.scrollHeight;
        }
      })
    })
    return {
      input,
      markdown_html,
      display,
      updatePreview,
      update_article
    }
  },
};
</script>

<style scoped lang="scss">
@import 'github-markdown-css/github-markdown-light.css';

.markdown-editor {
  display: flex;
  text-align: left;
  color: #000;

  .markdown-title {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 20px;
    text-align: center;
  }

  .markdown-input {
    flex: 1;
    height: 100vh;
    margin-right: 20px;
    border: 0;
    resize: none;
  }

  .markdown-body {
    flex: 1;
    padding-bottom: 2px;
    height: 100vh;
    /* 设置预览框高度为可视窗口的高度 */
    /* 如果内容超出最大高度，显示滚动条 */
    color: #2c3e50;
    background-color: #fff;
  }
  .markdown-preview {
    overflow: auto;
  }
}

.markdown-save {
  margin-top: 20px;
  text-align: right;
}
::v-deep .markdown-body pre{
  width: 90%;
  margin-left: 2%;
  background-color: rgba(175,184,193,0.2);
}
</style>
